import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios'

const DMTsurcharge = (props) => {

  const [pack, setpack] = useState();
  const [startvalue, setstartvalue] = useState();
  const [endvalue, setendvalue] = useState();
  const [surcharge, setsurcharge] = useState();
  const [flat, setflat] = useState();
  const [cashback, setcashback] = useState();
  const[currentuser, setCurrentUser] = useState();
  const[allusers, setallusers] = useState([]);
  const [userid, setuserid] = useState();


  const fetchPreDetail = async () => {
    try {
      const userResponse = await axios.get(`https://sunpay.co.in/api/users/${props.data.id}/`);
      const respUser = userResponse.data;
      setCurrentUser(respUser);
      const alluser = await axios.get(`https://sunpay.co.in/api/get_users/`)
      const alluserresp = alluser.data;
      const paruser = alluserresp.filter(option => option.role_id === 1);
      setallusers(paruser)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchPreDetail();
  }, []);

  return (
    <div className='p-4'>
        <div className=' bg-gray-500 p-4 pb-6 px-6'>
          <h1 className='text-3xl font-black p-0 text-white border-b-4'>Set/Update DMT Surcharge</h1>
            <div className='mt-4 flex gap-4 border-b-2 pb-4'>
            <label className="mt-2 text-white w-40 text-2xl">Member ID</label><br/>
            <select id="id" onChange={(e) => setuserid(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option selected>Select User</option>
                    {allusers.map(option => (
                        <option key={option.username} value={option.username} className="text-black">
                            {option.username}, {option.shop_name}
                        </option>
                    ))}
            </select>
            </div>

        <div className='mt-4 border-b-2 pb-4'>
              <div className =" relative overflow-x-auto shadow-md border-black ">    
                <table className=" text-sm text-left rtl:text-right mt-2 border border-black text-gray-500 dark:text-gray-400 border-collapse ">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr className='border border-black'>
                      <th scope="col" className="px-6 py-3 border border-black">S.No.</th>
                      <th scope="col" className="px-6 py-3 border border-black">Package</th>                      
                      <th scope="col" className="px-6 py-3 border border-black">Start Range</th>
                      <th scope="col" className="px-6 py-3 border border-black">End Range</th>
                      <th scope="col" className="px-6 py-3 border border-black">Surcharge</th>
                      <th scope="col" className="px-6 py-3 border border-black">Is Surcharge Flat</th>
                      <th scope="col" className="px-6 py-3 border border-black">Company</th>
                      <th scope="col" className="px-6 py-3 border border-black">Is Company Flat</th>
                      <th scope="col" className="px-6 py-3 border border-black">Distributor</th>
                      <th scope="col" className="px-6 py-3 border border-black">Is Distributor Flat</th>
                      <th scope="col" className="px-6 py-3 border border-black">Add Date</th>
                      <th scope="col" className="px-6 py-3 border border-black">Update Date</th>
                      <th scope="col" className="px-6 py-3 border border-black">Action</th>
                      </tr>
                    </thead>
                  
                    <tbody>
                      <tr className="bg-white border border-black dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                          
                      </tr>
                    </tbody>
               </table>
              </div> 
        </div>

        </div>
    </div>
  )
}

export default DMTsurcharge
