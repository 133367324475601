import React from 'react'

const Stafflist = () => {
  return (
    <div className='p-4'>
      <div className=' bg-gray-500 p-4 pb-6'>
      <h1 className='text-3xl font-black text-white border-b-4'>Staff List </h1>
        <div className='mt-4'>

        </div>
      </div>
    </div>
  )
}

export default Stafflist