import React from 'react'
import { useLocation } from 'react-router-dom';
import axios from 'axios'
import { NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';

const RightSidebar = (props) => {
    const localdata = props.data;
    const [user, setuser] = useState([]);
    const [totaldaymoneytran, setdaymoneytran] = useState([]);
    const [pendingdaymoneytran, setpendingdaymoneytran] = useState([]);
    const [refdaymoneytran, setrefdaymoneytran] = useState([]);
    const [totaldaybbps, setdaybbps] = useState([]);
    const [pendingdaybbps, setpendingdaybbps] = useState([]);
    const [refdaybbps, setrefdaybbps] = useState([]);


    const location = useLocation();

    const fetchuser = async () => {
      try {
        const response = await axios.get(`https://sunpay.co.in/api/users/${localdata.id}`)
        setuser(response.data);
        console.log('rightsidebar dashboard') 
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchalltrans = async () => {
      try {
        console.log('trans',localdata)
        //Money Transfer Data Get
          const moneytan = await axios.get(`https://sunpay.co.in/api/dmttransaction/${localdata.id}/`);
          const moneytanresp = moneytan.data.Data;
  
          // Get today's date in YYYY-MM-DD format
          const today = new Date().toISOString().split('T')[0];
  
          // Filter transactions where created_at is today
          const todaysTransactions = moneytanresp.filter(transaction => {
              const transactionDate = new Date(transaction.created_at).toISOString().split('T')[0];
              return transactionDate === today;
          });
          const todaysPendingTransactions = moneytanresp.filter(transaction => {
            const transactionDate = new Date(transaction.created_at).toISOString().split('T')[0];
            return transactionDate === today && transaction.transaction_status_display === 'PENDING';
          });
        const todaysRefundTransactions = moneytanresp.filter(transaction => {
          const transactionDate = new Date(transaction.created_at).toISOString().split('T')[0];
          return transactionDate === today && transaction.transaction_status_display === 'REFUND';
        });
  
          // Set the filtered transactions
          setdaymoneytran(todaysTransactions);
          setpendingdaymoneytran(todaysPendingTransactions)
          setrefdaymoneytran(todaysRefundTransactions)
      
      //Utilities Transfer Data Get
        const bbpstan = await axios.get(`https://sunpay.co.in/api/bbpstransaction/${localdata.id}/`);
        const bbpstanresp = bbpstan.data.data;

        // Filter transactions where created_at is today
        const todaysbbpsTransactions = bbpstanresp.filter(transaction => {
          const transactionDate = new Date(transaction.created_at).toISOString().split('T')[0];
          return transactionDate === today;
        });
        const todaysbbpsPendingTransactions = bbpstanresp.filter(transaction => {
          const transactionDate = new Date(transaction.created_at).toISOString().split('T')[0];
          return transactionDate === today && transaction.transaction_status_display === 'PENDING';
        });
        const todaysbbpsRefundTransactions = bbpstanresp.filter(transaction => {
          const transactionDate = new Date(transaction.created_at).toISOString().split('T')[0];
          return transactionDate === today && transaction.transaction_status_display === 'REFUND';
        });
          setdaybbps(todaysbbpsTransactions);
          setpendingdaybbps(todaysbbpsPendingTransactions)
          setrefdaybbps(todaysbbpsRefundTransactions)
      
  
        
      } catch (error) {
          console.error('Error fetching data:', error);
      }
    };

    useEffect(() => {
      fetchuser();
      fetchalltrans();
    }, [props.data.id]);

    const [open, setOpen] = useState(true);
  return (
    <div>
      <div className='recenttransaction p-2 border-2'>
          {/* Daily Transactions */}
          <div className='bg-slate-800 md:w-full text-xl  border-b-2 border-white mr-0 pr-0 text-center text-md text-white'>
            Daily Summary
          </div>
          <div className='flex flex-wrap my-2 gap-12 gap-y-16'>
            <ul className='flex flex-wrap p-2 text-center gap-4 bg-slate-800'>
                  {user.role_id === 2 ? (
                   <li className='border-2 md:w-24 px-4 font-black odd:bg-yellow-400 even:bg-green-700 even:text-white border-white'>
                   Fund Requests
                   <br /><h1 className='value text-md font-black t'>0</h1>
               </li>
              ) : (<>
              <li className='border-2 px-4  font-black odd:bg-yellow-400 even:bg-green-700 even:text-white border-white'>
                  Money transfer
                  <br /><h1 className='value text-md font-black t'>{totaldaymoneytran.length}</h1>
                </li>
                <li className='border-2 px-4  font-black odd:bg-yellow-400 even:bg-green-700 even:text-white border-white'>
                    AEPS/MATM
                    <br /><h1 className='value text-md font-black t'>0</h1>
                </li>
                <li className='border-2 px-4  font-black odd:bg-yellow-400 even:bg-green-700 even:text-white border-white'>
                    Aadhar Pay
                    <br /><h1 className='value text-md font-black t'>0</h1>
                </li>
                <li className='border-2 px-4  font-black odd:bg-yellow-400 even:bg-green-700 even:text-white border-white'>
                    Utilities Paid
                    <br /><h1 className='value text-md font-black t'>{totaldaybbps.length}</h1>
                </li>
                <li className='border-2 px-4  font-black odd:bg-yellow-400 even:bg-green-700 even:text-white border-white'>
                    Credit Card
                    <br /><h1 className='value text-md font-black t'>0</h1>
                </li>
              </>
                 
                )}      
            </ul>
          </div>
        

            {/* Pending Transactions */}
            <div className='bg-slate-800 md:w-full text-xl border-b-2 border-white mr-0 pr-0 text-center text-md text-white'>
                In progress transaction
            </div>

            <ul className='flex flex-wrap p-2 text-center  gap-4 bg-slate-800'>
              <li className='border-2 px-4  font-black odd:bg-yellow-400 even:bg-green-700 even:text-white border-white'>
                  Money transfer
                  <br /><h1 className='value text-md font-black t'>{pendingdaymoneytran.length}</h1>
              </li>
              <li className='border-2 px-4  font-black odd:bg-yellow-400 even:bg-green-700 even:text-white border-white'>
                  AEPS/MATM
                  <br /><h1 className='value text-md font-black t'>0</h1>
              </li>
              <li className='border-2 px-4  font-black odd:bg-yellow-400 even:bg-green-700 even:text-white border-white'>
                  Aadhar Pay
                  <br /><h1 className='value text-md font-black t'>0</h1>
              </li>
              <li className='border-2 px-4  font-black odd:bg-yellow-400 even:bg-green-700 even:text-white border-white'>
                  Utilities Paid
                  <br /><h1 className='value text-md font-black t'>{pendingdaybbps.length}</h1>
              </li>
              <li className='border-2 px-4  font-black odd:bg-yellow-400 even:bg-green-700 even:text-white border-white'>
                  Credit Card
                  <br /><h1 className='value text-md font-black t'>0</h1>
              </li>
            </ul>

            {/* Refund Transactions */}
            <div className='bg-slate-800 md:w-full text-xl  border-b-2 border-white mr-0 pr-0 text-center text-md text-white'>
                Refund Transactions
            </div>

            <ul className='flex flex-wrap p-2 text-center  gap-4 bg-slate-800'>
              <li className='border-2 px-4  font-black odd:bg-yellow-400 even:bg-green-700 even:text-white border-white'>
                  Money transfer
                  <br /><h1 className='value text-md font-black t'>{refdaymoneytran.length}</h1>
              </li>
              <li className='border-2 px-4  font-black odd:bg-yellow-400 even:bg-green-700 even:text-white border-white'>
                  AEPS/MATM
                  <br /><h1 className='value text-md font-black t'>0</h1>
              </li>
              <li className='border-2 px-4  font-black odd:bg-yellow-400 even:bg-green-700 even:text-white border-white'>
                  Aadhar Pay
                  <br /><h1 className='value text-md font-black t'>0</h1>
              </li>
              <li className='border-2 px-4  font-black odd:bg-yellow-400 even:bg-green-700 even:text-white border-white'>
                  Utilities Paid
                  <br /><h1 className='value text-md font-black t'>{refdaybbps.length}</h1>
              </li>
              <li className='border-2 px-4  font-black odd:bg-yellow-400 even:bg-green-700 even:text-white border-white'>
                  Credit Card
                  <br /><h1 className='value text-md font-black t'>0</h1>
              </li>
            </ul>
      </div>
    </div>
  )
}

export default RightSidebar
