import React from 'react'

const KYCdoclist = () => {
  return (
    <div>
      KYC Documents list Page
    </div>
  )
}

export default KYCdoclist
