import React from 'react'

const PermissionSettings = () => {
  return (
    <div>
      Permission Setting page
    </div>
  )
}

export default PermissionSettings
