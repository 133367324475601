import React, { useState, useEffect } from 'react';
import axios from 'axios';


const Voucher = () => {
  return (
    <div>
      <div className='Generate Voucher p-2'>
                <div className='bg-slate-300 p-2  border-2 border-red-200'>
                <h1 className='font-bold text-2xl border-b-2 border-gray-400'>Generate Voucher</h1>
                <div className='mt-4'>
                  <div className='md:flex md:flex-wrap gap-8'>
                      <label className="mt-2 text-white w-40 text-2xl">Payee Name</label><br/>
                      <input className=" text-xl px-4 border border-solid border-gray-300 rounded"  id="name" name="name" type="text" placeholder="Enter Payee Name" />
                  </div>
                </div>
                </div>
        </div>  
    </div>
  )
}

export default Voucher
