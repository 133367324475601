import React from 'react'

const Adcomplaints = () => {
  return (
    <div>
      Complaints Page
    </div>
  )
}

export default Adcomplaints
