import React, { useState } from 'react';
import navlogo from './NavImages/logo.png';
import './Navcss/Navbar.css';
import { NavLink } from 'react-router-dom';
import { BiSolidUserCircle } from 'react-icons/bi';

export default function StickyNavbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const togglenavbarcomp = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navigatelogin = () => {
    window.location.href = 'https://dev.sunpay.co.in/login';
  };

  const isTimeInRange = () => {
    const now = new Date();
    const startHour = 7;
    const endHour = 23;
    const currentHour = now.getHours();
    return currentHour >= startHour && currentHour < endHour;
  };

  return (
    <div className="navbarcomp">
      <nav className="w-full top-0 left-0 fixed bg-gradient-to-r from-white to-gray-100 animate-bg border-b border-gray-200 dark:border-gray-600 z-50 shadow-md">
        <div className="max-w-screen-xl flex flex-col md:flex-row items-center justify-between px-4 transition-all duration-300">
          {/* Logo Section */}
          <NavLink
            to="/"
            className="flex items-center ml-8 hover:bg-slate-200 p-2 rounded-md transition duration-300"
          >
            <img
              src={navlogo}
              className="h-[74px] w-40 hover:scale-110 transition-transform duration-300"
              alt="Logo"
            />
          </NavLink>

          {/* Navigation Links */}
          <div
            className={`items-center w-full md:flex justify-center transition-transform duration-300 ease-in-out ${
              isMenuOpen ? "block" : "hidden"
            }`}
            id="navbar-sticky"
          >
            <ul className="flex flex-col md:flex-row md:space-x-36 p-2 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:bg-transparent dark:bg-gray-800 md:dark:bg-transparent text-lg">
              <li>
                <NavLink
                  to="/"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded-md hover:bg-blue-500 hover:text-white transition-colors duration-300 md:hover:bg-transparent md:hover:text-blue-500 md:p-0"
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about-us"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded-md hover:bg-blue-500 hover:text-white transition-colors duration-300 md:hover:bg-transparent md:hover:text-blue-500 md:p-0"
                >
                  About
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/services"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded-md hover:bg-blue-500 hover:text-white transition-colors duration-300 md:hover:bg-transparent md:hover:text-blue-500 md:p-0"
                >
                  Services
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact-us"
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded-md hover:bg-blue-500 hover:text-white transition-colors duration-300 md:hover:bg-transparent md:hover:text-blue-500 md:p-0"
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>

          {/* Login Button and Menu Icon */}
          <div className="flex md:order-2 mt-4 md:mt-0">
            {isTimeInRange() && (
              <button
                type="button"
                onClick={navigatelogin}
                className="text-white bg-gradient-to-r from-blue-500 to-indigo-500 hover:from-indigo-500 hover:to-blue-500 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-2xl px-4 py-2 shadow-md transition-all duration-300 transform hover:scale-110"
              >
                <BiSolidUserCircle />
              </button>
            )}

            <button
              data-collapse-toggle="navbar-sticky"
              type="button"
              onClick={togglenavbarcomp}
              className={`inline-flex items-center justify-center text-sm text-gray-500 rounded-lg md:hidden p-2 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 transition-transform duration-300 ${
                isMenuOpen ? "rotate-90" : "rotate-0"
              }`}
              aria-controls="navbar-sticky"
              aria-expanded={isMenuOpen}
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                {isMenuOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                )}
              </svg>
            </button>
          </div>
        </div>
      </nav>
    </div>


  );
}
