import React from 'react'

const Assignservice = () => {
  return (
    <div>
      Assign service page
    </div>
  )
}

export default Assignservice
