import React from 'react'

const ViewVoucher = () => {
  return (
    <div>
        <div className='Generate Voucher p-2'>
                <div className='bg-slate-300 p-2  border-2 border-red-200'>
                    <h1 className='font-bold text-2xl border-b-2 border-gray-400'>All Voucher Reports</h1>
                    <div className='mt-4'>
                        
                    </div>
                </div>
        </div>    
    </div>
  )
}

export default ViewVoucher
