import React from 'react'

const AddRole = () => {
  return (
    <div>
      Add role page
    </div>
  )
}

export default AddRole
