import React from 'react'

const Masterrole = () => {
  return (
    <div>
      Master role page
    </div>
  )
}

export default Masterrole
