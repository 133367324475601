import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios'
import { Transition } from '@headlessui/react';

const UpdateModel = ({ isOpen, onClose, item, currentuser }) => {
  const [options, setOptions] = useState([]);
  const [bank_acc_number, setBank] = useState('');
  const [amount, setAmount] = useState(0);
  const [ref_id, setRefer] = useState('');
  const [payment_mode, setMode] = useState('');
  const [payment_date, setDate] = useState('');
  const [remark, setRemarks] = useState('');
  const [bank_name, setBankName] = useState('');
  const [comremark, setcomremark] = useState();


  const BASE_URL = 'http://:9000';

  const handleDownload = (url, filename) => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => alert('Error downloading the file:', error));
  };

  const fetchBank = async () => {
    try {
      const response = await axios.get('https://sunpay.co.in/api/companybanks/');
      setOptions(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchBank();
  }, []);

  useEffect(() => {
    if (item) {
      setBank(item.bank_acc_number || '');
      setAmount(item.amount || 0);
      setRefer(item.ref_id || '');
      setMode(item.transaction_type_display || '');
      setDate(item.created_at || '');
      setRemarks(item.payment_remark || '');
      setBankName(item.bank_name || '');
    }
  }, [item]);

  const handleClose = () => {
    onClose();
  };


  const approve = async (event) => {
    event.preventDefault();
    const transaction_id = item.ref_id;
    const action = 'FORCESUCCESS';
    const upduser = currentuser.id

    const requestBody = {
      transaction_id,
      action,
      upduser // Assuming you have the username from your auth system
    };

    console.log('Form submitted:', requestBody);

    const response = await fetch('https://sunpay.co.in/api/update_DMTtransaction/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const backresp = await response.json();
    const error = backresp.Error;
    console.log('Response:', backresp);
    if (backresp.Message === 'Transaction status changed to Success') {
      alert('Transaction Updated Successfully');
      onClose();
    } else if (error) {
      alert(error);
    } else {
      alert('Technical Error');
    }
  };

  const reinitiate = async (event) => {
    event.preventDefault();
    const transaction_id = item.ref_id;
    const action = 'REFUND';
    const upduser = currentuser.id

    const requestBody = {
      transaction_id,
      action,
      upduser
    };

    console.log('Form submitted:', requestBody);

    const response = await fetch('https://sunpay.co.in/api/update_DMTtransaction/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const backresp = await response.json();
    const error = backresp.Error;
    console.log('Response:', backresp);
    if (backresp.Message === 'Refund successfully') {
      alert('Refunded Successfully');
      onClose();
    } else if (error) {
      alert(error);
    } else {
      alert('Technical Error');
    }
  };

  const reject = async (event) => {
    event.preventDefault();
    const transaction_id = item.ref_id;
    const action = 'FORCEFAILED';
    const upduser = currentuser.id

    const requestBody = {
      transaction_id,
      action,
      upduser // Assuming you have the username from your auth system
    };

    console.log('Form submitted:', requestBody);

    const response = await fetch('https://sunpay.co.in/api/update_DMTtransaction/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const backresp = await response.json();
    const error = backresp.Error;
    console.log('Response:', backresp);
    if (backresp.Message === 'Transaction status changed to Failed') {
      alert('Failed Successfully');
      onClose();
    } else if (error) {
      alert(error);
    } else {
      alert('Technical Error');
    }
  };

  return (
    <Transition
      show={isOpen}
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50"
    >
      <div className="flex items-center justify-center min-h-screen">
        <Transition.Child
          enter="transition-transform duration-150"
          enterFrom="transform scale-95"
          enterTo="transform scale-100"
          leave="transition-transform duration-150"
          leaveFrom="transform scale-100"
          leaveTo="transform scale-95"
        >
          <div className="bg-white rounded-lg p-8 shadow-lg">
          
            <div className="mt-2 gap-4 border-b border-black flow-root">
              <div className="flex justify-between items-center">
                <h1 className="text-2xl font-semibold p-4 text-green-600">
                  DMT Report
                </h1>
                <button onClick={handleClose} className="mt-4 px-4 py-2 bg-gray-900 text-white rounded-lg">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          
            <div>
              <div className="md:flex md:flex-wrap md:gap-8 mt-4" >
                {/* Bank Select */}
                <div>
                  <label htmlFor="banks" className="text-lg">Account No</label>
                  <input
                    id="banks"
                    required
                    value={bank_acc_number}
                    readOnly
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                    
                </div>

                {/* Amount */}
                <div className="col-md-3">
                  <label htmlFor="Amount">Amount</label>
                  <input
                    type="text"
                    required
                    value={amount}
                    readOnly
                    name="Amount"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                {/* Bank reference number */}
                <div className="col-md-3">
                  <label htmlFor="Bank reference">Bank Reference No.</label>
                  <input
                    type="text"
                    required
                    value={ref_id}
                    readOnly
                    name="Bank reference"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                {/* Payment mode */}
                <div className="col-md-3">
                  <label htmlFor="Payment mode">Payment Mode</label>
                  <input
                    type="text"
                    required
                    value={payment_mode}
                    readOnly
                    name="Bank reference"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                {/* Payment Date */}
                <div className="col-md-3">
                  <label htmlFor="Payment date">Payment Date</label>
                  <input
                    id="dateRequired"
                    type="text"
                    value={payment_date}
                    readOnly
                    name="dateRequired"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="col-md-3"><br/>
                  <button type="submit" onClick={approve} className="bg-green-500 text-white rounded-md p-2">
                    Force Success
                  </button>
                </div>

                <div className="col-md-3"><br/>
                  <button type="submit" onClick={reinitiate} className="bg-yellow-400 text-white rounded-md p-2">
                    Refund
                  </button>
                </div>

                <div className="col-md-3"><br/>
                  <button type="submit" onClick={reject} className="bg-red-600 text-white rounded-md p-2">
                    Force Failed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition>
  );
};

const today = new Date();
const date = today.setDate(today.getDate()); 
const defaultValue = new Date(date).toISOString().split('T')[0]

const DMThistory = (props) => {

  const [fromDate, setFromDate] = useState(defaultValue);
  const [toDate, setToDate] = useState(defaultValue);
  const [alldmttrans, setAlldmttrans] = useState([]);
  const [showRow, setshowrow] = useState(false);
  const [currentuser, setCurrentUser] = useState();


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'fromDate') {
      setFromDate(value);
    } else if (name === 'toDate') {
      setToDate(value);
    }
  };


  const fetchPreDetail = async () => {
    try {
      const userResponse = await axios.get(`https://sunpay.co.in/api/users/${props.data.id}/`);
      const respUser = userResponse.data;
      setCurrentUser(respUser);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const showalltrans = async () => {
    const userid = parseInt(props.data.id);
    try {
      const transresp = await axios.get(`https://sunpay.co.in/api/dmttransaction/`);
      

  
      // Check if transresp.data exists and has the expected structure
      if (transresp.data && transresp.data.Data) {
        const resptran = transresp.data.Data;
    
        const filteredTrans = resptran.filter((item) => {
          const itemDate = new Date(item.created_at).toISOString().split('T')[0];
          return itemDate >= fromDate && itemDate <= toDate;
        });
  
        setAlldmttrans(filteredTrans);
        setshowrow(true);
      } else {
        alert('Unexpected response structure:', transresp.data);
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  useEffect(() => {
    fetchPreDetail();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleEditClick = (item) => {
    setSelectedItem(item); // Set the selected item
    setIsModalOpen(true);  // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    setSelectedItem(null); // Reset the selected item
  };

  const formatDate = (dateTimeString) => {
    const dateObj = new Date(dateTimeString);
    const dd = String(dateObj.getDate()).padStart(2, '0');
    const mm = String(dateObj.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yy = dateObj.getFullYear().toString().substr(-2);
    return `${dd}/${mm}/${yy}`;
  };

  // Function to format time as HH:MM:SS
  const formatTime = (dateTimeString) => {
    const dateObj = new Date(dateTimeString);
    const HH = String(dateObj.getHours()).padStart(2, '0');
    const MM = String(dateObj.getMinutes()).padStart(2, '0');
    const SS = String(dateObj.getSeconds()).padStart(2, '0');
    return `${HH}:${MM}:${SS}`;
  };


return (
  <div>
    <div className='DMTreport p-4'>
    <UpdateModel isOpen={isModalOpen} onClose={closeModal} currentuser={currentuser} item={selectedItem} />

      <div className='bg-slate-300 p-2 mt-4 border-2 border-red-200'>
      <h1 className=' font-bold text-2xl border-b-2 border-gray-400'>DMT TRANSACTIONS</h1>

      <div className='mt-4'>
      <div className='datefetch flow-root gap-6'>
            
            <div className='float-left'>
              <div className='datefetch md:flex md:flex-wrap gap-6'>
                  {/* From Date */}
                  <div className="col-md-3">
                    <label htmlFor="fromDate" className='text-white'>From Date</label>
                    <input
                      id="fromDate"
                      type="date"
                      onChange={handleChange}
                      name="fromDate"
                      defaultValue={defaultValue}
                      className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    />
                  </div>

                  {/* To Date */}
                  <div className="col-md-3">
                    <label htmlFor="toDate" className='text-white'>To Date</label>
                    <input
                      id="toDate"
                      type="date"
                      onChange={handleChange}
                      name="toDate"
                      defaultValue={defaultValue}
                      className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    />
                  </div>

                  <div className="col-md-3">
                    <input
                      type="submit"
                      value='Submit'
                      onClick={showalltrans}
                      className='mt-6 bg-blue-600 text-white border border-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    />
                  </div>
              </div>
            </div>

            <div className='float-right'>
                  <label htmlFor="Payment date" className='text-white text-xl'>Search</label>
                    <div className="col-md-3">
                      <input id="dateRequired" type="search" onChange={handleChange} name="dateRequired" className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'/>
                      {/* <input type="date" onChange={handleChange} ref={dateInputRef} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'/> */}
                    </div>
            </div>

          </div>

        <div className='mt-4'>
          <div className =" relative overflow-x-auto shadow-md border-black ">
               <table className=" text-sm text-left rtl:text-right mt-2 border border-black text-gray-500 dark:text-gray-400 border-collapse ">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr className='border border-black'>
                    {/* <th scope="col" className="px-6 py-3 border border-black">SNO</th>
                    <th scope="col" className="px-6 py-3 border border-black">Transaction ID</th>
                    <th scope="col" className="px-6 py-3 border border-black">Mobile No</th>
                    <th scope="col" className="px-6 py-3 border border-black">Beneficiary Name</th>
                    <th scope="col" className="px-6 py-3 border border-black">Amount</th>
                    <th scope="col" className="px-6 py-3 border border-black">Charge</th>
                    {/* <th scope="col" className="px-6 py-3 border border-black">Commission</th> 
                    <th scope="col" className="px-6 py-3 border border-black">Transaction Type</th>
                    <th scope="col" className="px-6 py-3 border border-black">UTR</th>
                    <th scope="col" className="px-6 py-3 border border-black">Transaction Status</th>
                    <th scope="col" className="px-6 py-3 border border-black">Transaction Date</th>
                    <th scope="col" className="px-6 py-3 border border-black">Receipt</th> */}

                      <th scope="col" className="px-6 py-3 border border-black">SNO</th>
                      <th scope="col" className="px-6 py-3 border border-black">Action</th>
                      <th scope="col" className="px-6 py-3 border border-black">Transaction Status</th>
                      <th scope="col" className="px-6 py-3 border border-black">Transaction ID</th>
                      <th scope="col" className="px-6 py-3 border border-black">Mobile No</th>
                      <th scope="col" className="px-6 py-3 border border-black">Beneficiary Name</th>
                      <th scope="col" className="px-6 py-3 border border-black">ShopName</th>
                      <th scope="col" className="px-6 py-3 border border-black">Login ID</th>
                      <th scope="col" className="px-6 py-3 border border-black">Amount</th>
                      <th scope="col" className="px-6 py-3 border border-black">Charge</th>
                      {/* <th scope="col" className="px-6 py-3 border border-black">Commission</th> */}
                      <th scope="col" className="px-6 py-3 border border-black">Transaction Type</th>
                      <th scope="col" className="px-6 py-3 border border-black">UTR</th>
                      <th scope="col" className="px-6 py-3 border border-black">Transaction Date</th>
                      <th scope="col" className="px-6 py-3 border border-black">Receipt</th>
                    </tr>
                  </thead>
                
                  {showRow && (
                  <tbody>
                  {alldmttrans.map((item, index) => (
                      <tr key={item.id} className='bg-white'>
                        
                        <td scope="col" className="px-6 py-3 border border-black whitespace-nowrap">{index+1}</td>
                        <td className="px-6 py-3 border border-black">
                                {item.transaction_status_display === 'PENDING' || item.transaction_status_display === 'REINITIATE' ? (
                                    <button onClick={() => handleEditClick(item)} className="px-4 py-2 bg-blue-500 text-white rounded-lg">Edit</button>
                                ) : (
                                    <div></div>
                                )}
                                </td>
                                <td className="px-6 py-3 border border-black whitespace-nowrap">
                                      <button
                                          className={`px-4 py-2 rounded-lg text-white ${
                                          item.transaction_status_display === 'PENDING' ? 'bg-yellow-300' :
                                          item.transaction_status_display === 'SUCCESS' ? 'bg-green-600' :
                                          item.transaction_status_display === 'FAILURE' ? 'bg-red-500' :
                                          item.transaction_status_display === 'REINITIATE' ? 'bg-gray-800' :
                                            'bg-none' // Default color if none of the above match
                                          }`}
                                        >
                                        {item.transaction_status_display}
                                        </button>                                </td>
                        <td scope="col" className="px-6 py-3 border border-black whitespace-nowrap">{item.ref_id}</td>
                        <td scope="col" className="px-6 py-3 border border-black whitespace-nowrap">{item.mobile}</td>
                        <td scope="col" className="px-6 py-3 border border-black whitespace-nowrap">{item.bene_name}</td>
                        <td scope="col" className="px-6 py-3 border border-black whitespace-nowrap">{item.shopname}</td>
                        <td scope="col" className="px-6 py-3 border border-black whitespace-nowrap">{item.user_display}</td>
                        <td scope="col" className="px-6 py-3 border border-black whitespace-nowrap">{item.amount}</td>
                        <td scope="col" className="px-6 py-3 border border-black whitespace-nowrap">{item.charge}</td>
                        {/* <td scope="col" className="px-6 py-3 border border-black">{item.created_at}</td> */}
                        <td scope="col" className="px-6 py-3 border border-black whitespace-nowrap">{item.transaction_type_display}</td>
                        <td scope="col" className="px-6 py-3 border border-black whitespace-nowrap">{item.ref_id}</td>
                        <td scope="col" className="px-6 py-3 border border-black whitespace-nowrap">{item.transaction_status_display}</td>
                        <td scope="col" className="px-6 py-3 border border-black whitespace-nowrap">{formatDate(item.created_at)}</td>
                        <td scope="col" className="px-6 py-3 border border-black whitespace-nowrap">
                            <input type="submit" name="Receipt" className='border border-gray-300 bg-blue-600 text-white text-sm rounded-lg  block w-full p-2.5 hover:cursor-pointer'/>
                        </td>

                      </tr>
                    ))}
                  </tbody>
                )}
               </table>
          </div>
        </div>
            
          </div>
        </div>
    </div> 
     
  </div>
)
}


export default DMThistory
