import React, { useState, useEffect } from 'react';
import axios from 'axios';

const today = new Date();
const defaultValue = today.toISOString().split('T')[0];

const FundDebit = (props) => {

  const navdata = props.data;
  const [user, setUser] = useState({});
  const [paruser, setparuser] = useState([]);
  const [payee, setpayee] = useState();
  const [amount, setAmount] = useState();
  const [Remarks, setRemarks] = useState();
  const [fromDate, setFromDate] = useState(defaultValue);
  const [toDate, setToDate] = useState(defaultValue);
  const [showrow, setshowrow] = useState(false);
  const [allwallettrans, setallwalltrans] = useState([]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`https://sunpay.co.in/api/users/${navdata.id}`);
        setUser(response.data);

        const parentresponse = await axios.get(`https://sunpay.co.in/api/get_users/`);
        const parentdata = parentresponse.data;
        const filteredOptions = parentdata.filter(option => option.parent_id === user.id);
        setparuser(filteredOptions);

      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUser();
  }, [navdata.id, user.id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'fromDate') {
      setFromDate(value);
    } else if (name === 'toDate') {
      setToDate(value);
    }
  };

  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  const funddebittrans = async () => {
    try {
      const transresp = await axios.get(`https://sunpay.co.in/api/wallettowallettransaction/${user.id}/`);
      const resptran = transresp.data.Data;

      const filteredTrans = resptran.filter((item) => {
        if (isValidDate(item.created_at)) {
          const itemDate = new Date(item.created_at).toISOString().split('T')[0];
          return itemDate >= fromDate && itemDate <= toDate;
        } else {
          alert(`Invalid date: ${item.created_at}`);
          return false;
        }
      });
      setallwalltrans(filteredTrans);
      setshowrow(true);
    } catch (error) {
      alert('Error fetching transactions:', error);
    }
  };

  return (
    <div className='Fundrequest p-4'>
      {/* Fund request part */}
      <div className='bg-slate-300 p-2 border-2 border-red-200'>
        <h1 className='font-bold text-2xl border-b-2 border-gray-400'>FUND DEBIT</h1>
        <div className='flex gap-x-8 p-4'>
          <div className='bg-slate-500'>
            <h1 className='bg-red-700 text-white text-center'>Fund Debit</h1>
            <div className='p-4'>
              <div>
                <select
                  id="id"
                  onChange={(e) => setpayee(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected>Select Payee</option>
                  {paruser.map(option => (
                    <option key={option.id} value={option.username}>
                      {option.username}
                    </option>
                  ))}
                </select>
              </div>

              <div className='mt-4'>
                <label className="mt-2 text-white w-52 text-2xl">Amount</label><br />
                <input
                  className="text-xl px-4 border border-solid border-gray-300 rounded"
                  onChange={(e) => setAmount(e.target.value)}
                  type="number"
                />
              </div>

              <div className='mt-4'>
                <label className="mt-2 text-white w-52 text-2xl">Remarks</label><br />
                <input
                  className="text-xl px-4 border border-solid border-gray-300 rounded"
                  onChange={(e) => setRemarks(e.target.value)}
                  type="text"
                />
              </div>
            </div>
          </div>

          <div className='bg-white' style={{ maxWidth: '70%', overflow: 'hidden' }}>
            <h1 className='bg-yellow-900 text-white text-center'>Fund Debit Report</h1>
            <div className='p-4 flex gap-x-12 gap-y-2  flex-wrap'>
              <div className="col-md-3">
                <label htmlFor="toDate">Choose Payee</label>
                <select
                  id="id"
                  onChange={(e) => setpayee(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected>Select Payee</option>
                  {paruser.map(option => (
                    <option key={option.id} value={option.username}>
                      {option.username}
                    </option>
                  ))}
                </select>
              </div>

              {/* From Date */}
              <div className="col-md-3">
                <label htmlFor="fromDate">From Date</label>
                <input
                  id="fromDate"
                  type="date"
                  onChange={handleChange}
                  name="fromDate"
                  value={fromDate}
                  className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                />
              </div>

              {/* To Date */}
              <div className="col-md-3">
                <label htmlFor="toDate">To Date</label>
                <input
                  id="toDate"
                  type="date"
                  onChange={handleChange}
                  name="toDate"
                  value={toDate}
                  className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                />
              </div>

              {/* Submit button */}
              <div className="col-md-3">
                <label htmlFor=""></label><br />
                <input
                  type="submit"
                  name="submit"
                  onClick={funddebittrans}
                  className='border border-gray-300 bg-blue-600 text-white text-sm rounded-lg block w-full p-2.5 hover:cursor-pointer'
                />
              </div>
            </div>
            <div className='mt-4 px-4' style={{ overflow: 'hidden' }}>
                <div className="overflow-x-auto shadow-md border-black relative" style={{ maxWidth: '100%' }}>
                  <table className="text-sm text-left rtl:text-right mt-2 border border-black text-gray-500 dark:text-gray-400 border-collapse">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr className='border border-black'>
                        <th scope="col" className="px-6 py-3 border border-black">SNO</th>
                        <th scope="col" className="px-6 py-3 border border-black">Transaction ID</th>
                        <th scope="col" className="px-6 py-3 border border-black">Type</th>
                        <th scope="col" className="px-6 py-3 border border-black">Mobile No</th>
                        <th scope="col" className="px-6 py-3 border border-black">Sender Merchant ID</th>
                        <th scope="col" className="px-6 py-3 border border-black">Amount</th>
                        <th scope="col" className="px-6 py-3 border border-black">Transaction Type</th>
                        <th scope="col" className="px-6 py-3 border border-black">Transaction Status</th>
                        <th scope="col" className="px-6 py-3 border border-black">Transaction Date</th>
                      </tr>
                    </thead>
                    {showrow && (
                      <tbody>
                        {allwallettrans.map((item, index) => (
                          <tr key={item.id} className='bg-white'>
                            <td scope="col" className="px-6 py-3 border border-black">{index + 1}</td>
                            <td scope="col" className="px-6 py-3 border border-black">{item.ref_id}</td>
                            <td scope="col" className="px-6 py-3 border border-black">{item.transaction_direction_display}</td>
                            <td scope="col" className="px-6 py-3 border border-black">{item.mobile}</td>
                            <td scope="col" className="px-6 py-3 border border-black">{item.sender}</td>
                            <td scope="col" className="px-6 py-3 border border-black">{item.amount}</td>
                            <td scope="col" className="px-6 py-3 border border-black">{item.transaction_type_display}</td>
                            <td scope="col" className="px-6 py-3 border border-black">{item.transaction_status_display}</td>
                            <td scope="col" className="px-6 py-3 border border-black">{item.created_at}</td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                </div>
              </div> 
          </div>
        </div>
      </div>
    </div>
  )
}

export default FundDebit;