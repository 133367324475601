import React, { useState, useEffect } from 'react';
import { HiMenuAlt1 } from "react-icons/hi";
import { MdOutlineDashboard } from "react-icons/md";
import { RiSettings4Line } from 'react-icons/ri';
import { TbReportAnalytics } from 'react-icons/tb';
import { GiReceiveMoney } from "react-icons/gi";
import { AiFillDatabase } from "react-icons/ai";
import { BsChatLeftDotsFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
import axios from 'axios';

const Sidebar = (props) => {
  const [user, setUser] = useState({});
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(null); // Track active dropdown

  const fetchUser = async () => {
    try {
      const userresponse = await axios.get(`https://sunpay.co.in/api/users/${props.data.id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      const respuser = userresponse.data;
      setUser(respuser);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [props.data.id]);

  const menus = [
    { name: "Dashboard", link: "/member/dashboard", icon: MdOutlineDashboard },
    { 
      name: "Funds", 
      icon: GiReceiveMoney, 
      dropdown: true, 
      submenus: [
        { name: "Fund Request", link: "/member/fund-request" },
        { name: "Fund Report", link: "/member/fundreport" },
      ]
    },
    user.role_id === 2 ? { 
      name: "Fund Manage", 
      icon: GiReceiveMoney, 
      dropdown: true, 
      submenus: [
        { name: "Fund Debit", link: "/member/fund-debit" },
        { name: "Fund Credit", link: "/member/fund-credit" },
      ]
    } : null,

    { name: "Reports", link: "/member/reports", icon: AiFillDatabase },
    { name: "Account Lagger", link: "/member/accountlagger", icon: TbReportAnalytics },
    { name: "Setting", link: "/member/settings", icon: RiSettings4Line },
    { name: "Complaint", link: "/member/complaints", icon: BsChatLeftDotsFill },
  ].filter(menu => menu !== null);

  const [open, setOpen] = useState(true);

  const toggleDropdown = (index) => {
    if (activeDropdownIndex === index) {
      setActiveDropdownIndex(null); // Close dropdown if it's already open
    } else {
      setActiveDropdownIndex(index); // Open the clicked dropdown
    }
  };

  return (
    <div className='flex gap-6'>
      <div className={`bg-blue-600 min-h-screen ${open ? "w-72" : "w-16"} duration-500 text-gray-100 px-4`}>
        <div className="py-3 flex justify-end">
          <HiMenuAlt1
            size={26}
            className="cursor-pointer"
            onClick={() => setOpen(!open)}
          />
        </div>
        <div className="mt-4 flex flex-col gap-4 relative">
          {menus.map((menu, i) => (
            <div key={i}>
              <Link to={menu.link} onClick={() => menu.dropdown && toggleDropdown(i)}>
                <div 
                  className={` ${menu?.margin ? "mt-5" : ""} group flex items-center text-xl gap-3.5 font-medium p-2 hover:bg-gray-800 rounded-md cursor-pointer`}
                >
                  <div>{React.createElement(menu?.icon, { size: "20" })}</div>
                  <h2 style={{ transitionDelay: `${i + 3}00ms`, }} className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"}`}>
                    {menu?.name}
                  </h2>
                  <h2 className={`${open && "hidden"} absolute left-48 bg-white font-semibold whitespace-pre text-gray-900 rounded-md drop-shadow-lg px-0 py-0 w-0 overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-14 group-hover:duration-300 group-hover:w-fit`}>
                    {menu?.name}
                  </h2>
                </div>
              </Link>
              {menu.dropdown && activeDropdownIndex === i && (
                <div className={`ml-8 mt-2 flex flex-col gap-2 ${!open && "hidden"}`}>
                  {menu.submenus.map((submenu, j) => (
                    <Link to={submenu.link} key={j} className="group flex items-center text-lg gap-3.5 font-medium p-2 hover:bg-gray-800 rounded-md">
                      <div className="ml-4">{React.createElement(GiReceiveMoney, { size: "18" })}</div>
                      <h2 className={`whitespace-pre duration-500 ${!open && "opacity-0 translate-x-28 overflow-hidden"}`}>
                        {submenu.name}
                      </h2>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
