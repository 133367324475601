import React from 'react'
import { useState, useEffect } from 'react';
import StickyNavbar from '../Navbar/StickyNavbar';
import Footer from '../Footer/Footer'

const RefundCancellation = () => {
    const [activeTab, setActiveTab] = useState("english");
    const [showNavbar, setShowNavbar] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
  
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
  
      if (currentScrollY > lastScrollY) {
        // Scrolling down
        setShowNavbar(false);
      } else {
        // Scrolling up
        setShowNavbar(true);
      }
  
      setLastScrollY(currentScrollY);
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [lastScrollY]);

  return (
    <div >
        {/* Navbar */}
        <div className={`sticky-navbar ${showNavbar ? 'visible' : 'hidden'} `}>
        <StickyNavbar />
        </div>

        <div className='p-8 bg-white mt-20 bg-gradient-to-br from-red-500 to-green-300'>
        <div className="container mx-auto">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <ul className="flex border-b" role="tablist">
                <li className="mr-1">
                  <button
                    className={`bg-white inline-block py-2 px-4 font-semibold ${
                      activeTab === "english" ? "border-l border-t border-r rounded-t text-blue-700" : ""
                    }`}
                    onClick={() => setActiveTab("english")}
                  >
                    English
                  </button>
                </li>
                <li className="mr-1">
                  <button
                    className={`bg-white inline-block py-2 px-4 font-semibold ${
                      activeTab === "hindi" ? "border-l border-t border-r rounded-t text-blue-700" : ""
                    }`}
                    onClick={() => setActiveTab("hindi")}
                  >
                    हिंदी
                  </button>
                </li>
              </ul>

              <div className="tab-content p-4 border border-t-0 rounded-b">
                {activeTab === "english" && (
                  <div id="english" className="tab-pane active">
                    <div className="refund">
                      <div className="refund__main-content-container">
                        <div className="refund__main-content-container__main-content">
                          <div id="refund bg-white">
                            <div className="refund__main-content-container__main-content__text">
                              <p>
                                <span>
                                  <strong>Terms and Conditions</strong>
                                </span>
                              </p>
                              <p>
                               Welcome to SunPay Technology. By accessing or using our services, you agree to comply with and be bound by the following terms and conditions (the "Terms"). If you do not agree to these Terms, please do not use our services.
                                <br />
                                <br />
                                We will try our best to create suitable design concepts for
                                our clients. In case any client is not completely satisfied
                                with our products we can provide a refund. If paid by credit
                                card, refunds will be issued to the original credit card
                                provided at the time of purchase, and in the case of payment
                                gateway name payments, refunds will be made to the same
                                account.
                                <br />
                                <br />
                                <b>Cancellation Policy</b>
                                <br />
                                For cancellations, please contact us via call or email
                                mentioned on our Contact-us Link. Requests received later
                                than 48-hours prior to the end of the current service period
                                will be treated as cancellations for the next service period.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeTab === "hindi" && (
                  <div id="hindi" className="tab-pane active">
                    <div className="refund">
                      <div className="refund__main-content-container">
                        <div className="refund__main-content-container__main-content">
                          <div id="refund">
                            <div className="refund__main-content-container__main-content__text">
                              <p>
                                <span>
                                  <strong>Sunpay के लिए धनवापसी नीति</strong>
                                </span>
                              </p>
                              <p>
                                हमारा उद्देश्य ग्राहक की 100% संतुष्टि है। यदि आप प्रदान की
                                गई सेवाओं से संतुष्ट नहीं हैं, तो हम केवल तब धन वापस करेंगे
                                जब कारण वास्तविक और जांच के बाद सिद्ध हों। कृपया किसी भी
                                सेवा का उपयोग करने से पहले उसकी जानकारी पढ़ें, इसमें आपको
                                मिलने वाली सेवाओं का विवरण होता है।
                                <br />
                                <br />
                                <b>धनवापसी नीति</b>
                                <br />
                                हम अपने ग्राहकों के लिए उपयुक्त डिज़ाइन अवधारणाएँ बनाने का
                                प्रयास करेंगे। यदि कोई ग्राहक हमारे उत्पादों से पूरी तरह
                                संतुष्ट नहीं है, तो हम धनवापसी कर सकते हैं। यदि क्रेडिट
                                कार्ड द्वारा भुगतान किया गया है, तो धनवापसी उस मूल क्रेडिट
                                कार्ड पर की जाएगी जो खरीद के समय प्रदान किया गया था, और
                                भुगतान गेटवे के नाम से भुगतान के मामले में, धनवापसी उसी
                                खाते में की जाएगी।
                                <br />
                                <br />
                                <b>रद्दीकरण नीति</b>
                                <br />
                                कृपया रद्दीकरण के लिए हमें हमारी संपर्क-करें लिंक के
                                माध्यम से कॉल या ईमेल करें। वर्तमान सेवा अवधि के अंत से 48
                                घंटे पहले प्राप्त अनुरोधों को अगली सेवा अवधि के रद्दीकरण के
                                रूप में माना जाएगा।
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        </div>

      {/* Footer */}
      <section className='Footer'>
        <Footer/>
      </section>

    </div>
  )
}

export default RefundCancellation
