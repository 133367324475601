import React from 'react'
import { useState } from 'react'
import axios from 'axios'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

const today = new Date();
const date = today.setDate(today.getDate()); 
const defaultValue = new Date(date).toISOString().split('T')[0]

const dd = String(today.getDate()).padStart(2, '0');
const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
const yy = String(today.getFullYear()).slice(-2);
const hh = String(today.getHours()).padStart(2, '0');
const min = String(today.getMinutes()).padStart(2, '0');
const ss = String(today.getSeconds()).padStart(2, '0');

const currentTime = `${dd}${mm}${yy}${hh}${min}${ss}`;

const AddUPI = (props) => {

  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const [beneficiary_name,setbeneficiary_name] = useState();
  const [upiid, setupiid] = useState();
  const [mobile_number, setmobile_number] = useState();
  const [bankname, setbankname] = useState();
  const [email, setemail] = useState();
  const [userid, setcurrentuser] = useState();
  const [ifsc, setifsc] = useState();
  

  useEffect(() => {
    setupiid(data.upiid)
  }, []);

 


    const fetchUser = async () => {
      try {
        const userResponse = await axios.get(`https://sunpay.co.in/api/users/${props.data.id}`);
    
  
        const repuserid = userResponse.data.id;
        setcurrentuser(repuserid);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

  useEffect(() => {
    fetchUser();
  }, []);

  const getCurrentTime = () => {
    // Get the current time
    const now = new Date();
    // Format the time as desired (e.g., using ISO string or custom format)
    return now.toISOString().replace(/\D/g, ''); // Remove all non-digit characters
  }
  
  const upiverify = async () => {
    const initial_string = "verUPI";
    // Prefix string
    const prefix_string = userid;
    // Get the current time dynamically
    const currentTime = getCurrentTime();
    // Concatenate prefix to initial string with current time
    const merchant_reference_id = initial_string + prefix_string + currentTime;
    console.log(merchant_reference_id);
    
    try {
      const response = await fetch('https://sunpay.co.in/api/verify_upi/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ upiid, merchant_reference_id }),
      });
      
      const bankverifyresponse = await response.json();
      console.log(bankverifyresponse.Response.data);
      const respmessage = bankverifyresponse.Message;
  
      if (respmessage === 'UPI verified successfully') {
        const respresponsename = bankverifyresponse.Response.data.name_as_per_bank;
        const ifsccode = bankverifyresponse.Response.data.bank_ifsc_code;
        setifsc(ifsccode)
        setbeneficiary_name(respresponsename);
        alert('Bank details verified');
      } else {
        alert('Unable to process now. Kindly contact your Administration.');
      }
    } catch (error) {
      alert('Something went wrong:', error);
    }
  };
 
  const handlesubmit = async (e) => {
    console.log(beneficiary_name, upiid, email, mobile_number, userid, ifsc)
    try {
      const response = await fetch('https://sunpay.co.in/api/zpayaddupi/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ beneficiary_name, upiid, email, mobile_number, userid, ifsc}),
      });
      const upiaddresponse = await response.json();
      // console.log(bankverifyresponse);
      
      const respmessage = upiaddresponse.Message;
      console.log(upiaddresponse)
      if (respmessage === 'Added UPI ID') {
        alert ('Added UPI ID successfully')
        navigate('/member/upi-transfer/');
      }
      else {
        alert ('Unable to add Bank Account Details. Please try again later.')
      }
    } catch (error) {
      alert('Something went wrong')
    }
 
  }

  return (
    <div className='AddUPI text-lg p-4'>
        <div className='bg-slate-300 p-2 border-2 font-black border-red-200'>
        <div className='AddUPIform'>
        
        {/* <div className='mt-4'>
          <div className='flex gap-2'>  
              <label htmlFor="Payment mode">Mobile No</label>
              <input type="text" name="accno" readOnly placeholder='Enter Mobile Number'  defaultValue={data.number} className='md:w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 hover:cursor-pointer dark:focus:border-blue-500'/>
          </div>
        </div> */}
        

        <div className='mt-4'>
          <div className='flex gap-2'>  
              <label htmlFor="Payment mode">UPI ID.</label>
              <input type="text" value={upiid} readOnly className='md:w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 hover:cursor-pointer dark:focus:border-blue-500'/>
          </div>
        </div>


        <button type="submit" onClick={upiverify} className='text-lg px-2 bg-yellow-500 border border-black hover:bg-slate-600 hover:text-white rounded-md'>
                    Verify
        </button>

        <div className='mt-4'>
          <div className='flex gap-2'>  
              <label htmlFor="Payment mode">Bank Name</label>
              <input type="text" name="accno" readOnly value={beneficiary_name} className='md:w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 hover:cursor-pointer dark:focus:border-blue-500'/>
          </div>
        </div>

        <div className='mt-4'>
          <div className='flex gap-2'>  
              <label htmlFor="Payment mode">Email ID</label>
              <input type="text" name="mobileno" onChange={(e) => setemail(e.target.value)} className='md:w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 hover:cursor-pointer dark:focus:border-blue-500'/>
          </div>
        </div>

        <div className='mt-4'>
          <div className='flex gap-2'>  
              <label htmlFor="Payment mode">Mobile Number</label>
              <input type="text" name="mobileno" onChange={(e) => setmobile_number(e.target.value)} className='md:w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 hover:cursor-pointer dark:focus:border-blue-500'/>
          </div>
        </div>
       
            <div className='mt-4 flex gap-2 text-white'> 

                <button type="submit" onClick={handlesubmit} className='text-lg px-2 bg-blue-600 border border-black hover:bg-red-700 hover:text-white rounded-md'>
                  Submit
                </button>
                  
                <NavLink to='/member/upi-transfer'>
                  <button type="submit" className='text-lg px-2 bg-red-600 border border-black hover:text-white rounded-md'>
                    Back
                  </button>
                </NavLink>
                  
                

                 
                
              </div>
        </div>
        </div>
    </div>
  )
}

export default AddUPI
