import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios'

const today = new Date();
const defaultValue = today.toISOString().split('T')[0];

const WalletTransactionreport = (props) => {
 
  const [currentuser, setcuruser] = useState({});

  const fetchUser = async () => {
    try {
      const userresponse = await axios.get(`https://sunpay.co.in/api/users/${props.data.id}`);
      const respuser = userresponse.data;
      setcuruser(respuser);

      if(respuser.role_id==2){
        const alluser = await axios.get(`https://sunpay.co.in/api/get_users/`)
        const alluserresp = alluser.data;
        const paruser = alluserresp.filter(option => option.parent_id === respuser.id);
        setallusers(paruser)
      }
    } catch (error) {
      alert('Error fetching data:', error); 
    }
  };

  const getdmttrans = async() => {
    try {
      const transresp = await axios.get(`https://sunpay.co.in/api/wallettowallettransaction/${currentuser.id}/`);
      const resptran = transresp.data.Data;
      
      const filteredTrans = resptran.filter((item) => {
        if (isValidDate(item.created_at)) {
          const itemDate = new Date(item.created_at).toISOString().split('T')[0];
          return itemDate >= fromDate && itemDate <= toDate;
        } else {
          alert(`Invalid date: ${item.created_at}`);
          return false;
        }
      });
      setallwalltrans(filteredTrans);
      setshowrow(true);
    } catch (error) {
      alert('Error fetching transactions:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'fromDate') {
      setFromDate(value);
    } else if (name === 'toDate') {
      setToDate(value);
    }
  };

  const showalltrans = async () => {
    try {
      const transresp = await axios.get(`https://sunpay.co.in/api/wallettowallettransaction/${userid}/`);
      const resptran = transresp.data.Data;
      
      const filteredTrans = resptran.filter((item) => {
        if (isValidDate(item.created_at)) {
          const itemDate = new Date(item.created_at).toISOString().split('T')[0];
          return itemDate >= fromDate && itemDate <= toDate;
        } else {
          alert(`Invalid date: ${item.created_at}`);
          return false;
        }
      });

      setallwalltrans(filteredTrans);
      console.log(allwallettrans)
      setshowrow(true);
    } catch (error) {
      alert('Error fetching transactions:', error);
    }
  };

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(savePosition);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  function savePosition(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
  }
  
  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  const [allwallettrans, setallwalltrans] = useState([]);
  const [showrow, setshowrow] = useState(false)
  const [allusers,setallusers] = useState([])
  const [userid, setuserid] = useState()
  const [fromDate, setFromDate] = useState(defaultValue);
  const [toDate, setToDate] = useState(defaultValue);

  useEffect(() => {
    fetchUser();
    getLocation();
  }, [props.data.id]);

  return (
    <div>
      {currentuser.role_id === 1 ? (
          <div className='DMTreport p-4'>

            <div className='bg-slate-300 p-2 mt-4 border-2 border-red-200'>
            <h1 className=' font-bold text-2xl border-b-2 border-gray-400'>WALLET TO WALLET TRANSACTIONS</h1>

            <div className='mt-4'>
              <div className='datefetch md:flex md:flex-wrap gap-6'>
                {/* From Date */}
                <div className="col-md-3">
              <label htmlFor="fromDate">From Date</label>
              <input
                id="fromDate"
                type="date"
                onChange={handleChange}
                name="fromDate"
                value={fromDate}
                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
              />
            </div>
            {/* To Date */}
            <div className="col-md-3">
              <label htmlFor="toDate">To Date</label>
              <input
                id="toDate"
                type="date"
                onChange={handleChange}
                name="toDate"
                value={toDate}
                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
              />
            </div>
            


                {/* Submit button */}
                <div className="col-md-3">
                  <label htmlFor=""></label><br />
                  <input type="submit" name="submit" onClick={getdmttrans} className='border border-gray-300 bg-blue-600 text-white text-sm rounded-lg  block w-full p-2.5 hover:cursor-pointer'/>
                </div>
              </div>

                <div className='mt-4'>
                  <div className =" relative overflow-x-auto shadow-md border-black ">
                  <table className=" text-sm text-left rtl:text-right mt-2 border border-black text-gray-500 dark:text-gray-400 border-collapse ">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr className='border border-black'>
                        <th scope="col" className="px-6 py-3 border border-black">SNO</th>
                        <th scope="col" className="px-6 py-3 border border-black">Transaction ID</th>
                        <th scope="col" className="px-6 py-3 border border-black">Type</th>
                        <th scope="col" className="px-6 py-3 border border-black">Mobile No</th>
                        <th scope="col" className="px-6 py-3 border border-black">Sender Merchant ID</th>
                        <th scope="col" className="px-6 py-3 border border-black">Amount</th>
                        <th scope="col" className="px-6 py-3 border border-black">Transaction Type</th>
                        <th scope="col" className="px-6 py-3 border border-black">Transaction Status</th>
                        <th scope="col" className="px-6 py-3 border border-black">Transaction Date</th>
                      </tr>
                    </thead>
                  
                    {showrow && (
                    <tbody>
                    {allwallettrans.map((item, index) => (
                        <tr key={item.id} className='bg-white'>
                          
                          <td scope="col" className="px-6 py-3 border border-black">{index+1}</td>
                          <td scope="col" className="px-6 py-3 border border-black">{item.ref_id}</td>
                          <td scope="col" className="px-6 py-3 border border-black">{item.transaction_direction_display}</td>
                          <td scope="col" className="px-6 py-3 border border-black">{item.mobile}</td>
                          <td scope="col" className="px-6 py-3 border border-black">{item.sender}</td>
                          <td scope="col" className="px-6 py-3 border border-black">{item.amount}</td>
                          <td scope="col" className="px-6 py-3 border border-black">{item.transaction_type_display}</td>
                          <td scope="col" className="px-6 py-3 border border-black">{item.transaction_status_display}</td>
                          <td scope="col" className="px-6 py-3 border border-black">{item.created_at}</td>
                        

                        </tr>
                      ))}
                    </tbody>
                  )}
                 </table>
                  </div>
                </div>
              </div>
            </div>
          </div> 
      ):
      (
        <div className='DMTreport p-4'>

            <div className='bg-slate-300 p-2 mt-4 border-2 border-red-200'>
              <h1 className=' font-bold text-2xl border-b-2 border-gray-400'>WALLET TO WALLET TRANSACTIONS</h1>

              <div className='mt-4'>
                <div className='datefetch md:flex md:flex-wrap gap-6'>
                      
                      {/* From Date */}
                      <div className="col-md-3">
                      <label htmlFor="fromDate">From Date</label>
                      <input
                        id="fromDate"
                        type="date"
                        onChange={handleChange}
                        name="fromDate"
                        value={fromDate}
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                      />
                      </div>

                      {/* To Date */}
                      <div className="col-md-3">
                        <label htmlFor="toDate">To Date</label>
                        <input
                          id="toDate"
                          type="date"
                          onChange={handleChange}
                          name="toDate"
                          value={toDate}
                          className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                        />
                      </div>
                      
                      {/* Set retailer */}
                      <div className="col-md-3">
                          <label htmlFor="Payment date">Select Retailer</label>
                          <select id="id" onChange={(e) => setuserid(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option selected>Select User</option>
                                {allusers.map(option => (
                                <option key={option.username} value={option.id} className="text-black">
                                    {option.username}
                                </option>
                                ))}
                          </select>
                      </div>
                      
                      {/* Submit button */}
                      <div className="col-md-3">
                        <label htmlFor=""></label><br />
                        <input type="submit" name="submit" onClick={showalltrans} className='border border-gray-300 bg-blue-600 text-white text-sm rounded-lg  block w-full p-2.5 hover:cursor-pointer'/>
                      </div>
                </div>
              </div>
              <div className='mt-4'>
                  <div className =" relative overflow-x-auto shadow-md border-black ">
                  <table className=" text-sm text-left rtl:text-right mt-2 border border-black text-gray-500 dark:text-gray-400 border-collapse ">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr className='border border-black'>
                        <th scope="col" className="px-6 py-3 border border-black">SNO</th>
                        <th scope="col" className="px-6 py-3 border border-black">Transaction ID</th>
                        <th scope="col" className="px-6 py-3 border border-black">Type</th>
                        <th scope="col" className="px-6 py-3 border border-black">Mobile No</th>
                        <th scope="col" className="px-6 py-3 border border-black">Sender Merchant ID</th>
                        <th scope="col" className="px-6 py-3 border border-black">Amount</th>
                        <th scope="col" className="px-6 py-3 border border-black">Transaction Type</th>
                        <th scope="col" className="px-6 py-3 border border-black">Transaction Status</th>
                        <th scope="col" className="px-6 py-3 border border-black">Transaction Date</th>
                      </tr>
                    </thead>
                  
                    {showrow && (
                    <tbody>
                    {allwallettrans.map((item, index) => (
                        <tr key={item.id} className='bg-white'>
                          
                          <td scope="col" className="px-6 py-3 border border-black">{index+1}</td>
                          <td scope="col" className="px-6 py-3 border border-black">{item.ref_id}</td>
                          <td scope="col" className="px-6 py-3 border border-black">{item.transaction_direction_display}</td>
                          <td scope="col" className="px-6 py-3 border border-black">{item.mobile}</td>
                          <td scope="col" className="px-6 py-3 border border-black">{item.sender}</td>
                          <td scope="col" className="px-6 py-3 border border-black">{item.amount}</td>
                          <td scope="col" className="px-6 py-3 border border-black">{item.transaction_type_display}</td>
                          <td scope="col" className="px-6 py-3 border border-black">{item.transaction_status_display}</td>
                          <td scope="col" className="px-6 py-3 border border-black">{item.add_date}</td>
                        

                        </tr>
                      ))}
                    </tbody>
                  )}
                 </table>
                  </div>
              </div>
            </div>
        </div>
      )} 
    </div>
  )
}

export default WalletTransactionreport




