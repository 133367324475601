import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Transition } from '@headlessui/react';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const UpdateModel = ({ isOpen, onClose, item, currentuser }) => {
  const [options, setOptions] = useState([]);
  const [bank_acc_number, setBank] = useState('');
  const [amount, setAmount] = useState(0);
  const [ref_number, setRefer] = useState('');
  const [payment_mode, setMode] = useState('');
  const [payment_date, setDate] = useState('');
  const [remark, setRemarks] = useState('');
  const [bank_name, setBankName] = useState('');
  const [comremark, setcomremark] = useState();


  const BASE_URL = 'http://:9000';

  const handleDownload = (url, filename) => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch((error) => alert('Error downloading the file:', error));
  };

  const fetchBank = async () => {
    try {
      const response = await axios.get('https://sunpay.co.in/api/companybanks/');
      setOptions(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchBank();
  }, []);

  useEffect(() => {
    if (item) {
      setBank(item.bank_acc_number || '');
      setAmount(item.amount || 0);
      setRefer(item.bank_ref_number || '');
      setMode(item.payment_mode_display || '');
      setDate(item.payment_date || '');
      setRemarks(item.remark || '');
      setBankName(item.bank_name || '');
    }
  }, [item]);

  const handleClose = () => {
    onClose();
  };


  const approve = async (event) => {
    event.preventDefault();
    const transaction_id = item.reference_number;
    const action = 'APPROVE';
    const upduser = currentuser.id

    const requestBody = {
      transaction_id,
      action,
      amount,
      bank_name,
      bank_acc_number,
      ref_number,
      payment_mode,
      payment_date,
      remark,
      upduser // Assuming you have the username from your auth system
    };

    console.log('Form submitted:', requestBody);

    const response = await fetch('https://sunpay.co.in/api/update_fund_request/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const backresp = await response.json();
    const error = backresp.Error;
    console.log('Response:', backresp);
    if (backresp.Message === 'Updated successfully') {
      alert('Fund Request Updated Successfully');
      onClose();
    } else if (error) {
      alert(error);
    } else {
      alert('Technical Error');
    }
  };

  const reinitiate = async (event) => {
    event.preventDefault();
    const transaction_id = item.reference_number;
    const action = 'REINITIATE';
    const upduser = currentuser.id

    const requestBody = {
      transaction_id,
      action,
      amount,
      bank_name,
      bank_acc_number,
      ref_number,
      payment_mode,
      payment_date,
      remark,
      upduser,
      comremark // Assuming you have the username from your auth system
    };

    console.log('Form submitted:', requestBody);

    const response = await fetch('https://sunpay.co.in/api/update_fund_request/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const backresp = await response.json();
    const error = backresp.Error;
    console.log('Response:', backresp);
    if (backresp.Message === 'Reinitiation successful') {
      alert('Fund Request Reinitiation Successfully');
      onClose();
    } else if (error) {
      alert(error);
    } else {
      alert('Technical Error');
    }
  };

  const reject = async (event) => {
    event.preventDefault();
    const transaction_id = item.reference_number;
    const action = 'FAILURE';

    const requestBody = {
      transaction_id,
      action,
      amount,
      bank_name,
      bank_acc_number,
      ref_number,
      payment_mode,
      payment_date,
      remark,
      username: currentuser.id, // Assuming you have the username from your auth system
    };

    console.log('Form submitted:', requestBody);

    const response = await fetch('https://sunpay.co.in/api/update_fund_request/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const backresp = await response.json();
    const error = backresp.Error;
    console.log('Response:', backresp);
    if (backresp.Message === 'Updated successfully') {
      alert('Fund Request Updated Successfully');
      onClose();
    } else if (error) {
      alert(error);
    } else {
      alert('Technical Error');
    }
  };


  return (
    <Transition
      show={isOpen}
      enter="transition-opacity duration-150"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50"
    >
      <div className="flex items-center justify-center min-h-screen">
        <Transition.Child
          enter="transition-transform duration-150"
          enterFrom="transform scale-95"
          enterTo="transform scale-100"
          leave="transition-transform duration-150"
          leaveFrom="transform scale-100"
          leaveTo="transform scale-95"
        >
          <div className="bg-white rounded-lg p-8 shadow-lg">
          
            <div className="mt-2 gap-4 border-b border-black flow-root">
              <div className="flex justify-between items-center">
                <h1 className="text-2xl font-semibold p-4 text-green-600">
                  MANAGE FUND REQUEST
                </h1>
                <button onClick={handleClose} className="mt-4 px-4 py-2 bg-gray-900 text-white rounded-lg">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          
            <div>
              <div className="md:flex md:flex-wrap md:gap-8 mt-4" >
                {/* Bank Select */}
                <div>
                  <label htmlFor="banks" className="text-lg">Bank Name</label>
                  <input
                    id="banks"
                    required
                    value={bank_acc_number}
                    readOnly
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                    
                </div>

                {/* Amount */}
                <div className="col-md-3">
                  <label htmlFor="Amount">Amount</label>
                  <input
                    type="text"
                    required
                    value={amount}
                    readOnly
                    name="Amount"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                {/* Bank reference number */}
                <div className="col-md-3">
                  <label htmlFor="Bank reference">Bank Reference No.</label>
                  <input
                    type="text"
                    required
                    value={ref_number}
                    readOnly
                    name="Bank reference"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                {/* Payment mode */}
                <div className="col-md-3">
                  <label htmlFor="Payment mode">Payment Mode</label>
                  <input
                    type="text"
                    required
                    value={payment_mode}
                    readOnly
                    name="Bank reference"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                {/* Payment Date */}
                <div className="col-md-3">
                  <label htmlFor="Payment date">Payment Date</label>
                  <input
                    id="dateRequired"
                    type="date"
                    value={payment_date}
                    readOnly
                    name="dateRequired"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                {/* Remark */}
                <div className="col-md-3">
                  <label htmlFor="remark">Remark</label>
                  <input
                    type="text"
                    required
                    value={remark}
                    readOnly
                    name="remark"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                {/* Receipt */}
                <div className="col-md-3">
                  <label htmlFor="remark">Receipt</label>
                  {item && item.receipt && (
                  <img 
                  src={`${BASE_URL}/${item.receipt}`} 
                  alt="Receipt" 
                  className="w-40 h-40 object-cover cursor-pointer" 
                  onClick={() => handleDownload(`${BASE_URL}/${item.receipt}`, `receipt_${item.receipt}.png`)}
                  />
                  )}
                </div>

                <div className="col-md-3">
                  <label htmlFor="remark">Company Remark</label>
                  <input
                    type="text"
                    required
                    value={comremark}
                    onChange={(e) => setcomremark(e.target.value)}
                    name="remark"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="col-md-3"><br/>
                  <button type="submit" onClick={approve} className="bg-green-500 text-white rounded-md p-2">
                    Approve
                  </button>
                </div>

                <div className="col-md-3"><br/>
                  <button type="submit" onClick={reinitiate} className="bg-yellow-400 text-white rounded-md p-2">
                    Re-Initiate
                  </button>
                </div>

                <div className="col-md-3"><br/>
                  <button type="submit" onClick={reject} className="bg-red-600 text-white rounded-md p-2">
                    Reject
                  </button>
                </div>
              </div>
            </div>

           
           
          </div>
        </Transition.Child>
      </div>
    </Transition>
  );
};

const today = new Date();
const defaultValue = today.toISOString().split('T')[0];

const FundRequest = (props) => {

  const [fromDate, setFromDate] = useState(defaultValue);
  const [toDate, setToDate] = useState(defaultValue);
  const [allFundRequest, setAllFundRequest] = useState([]);
  const [showRow, setshowrow] = useState(false);
  const [currentuser, setCurrentUser] = useState();


  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'fromDate') {
      setFromDate(value);
    } else if (name === 'toDate') {
      setToDate(value);
    }
  };

  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };


  const fetchPreDetail = async () => {
    try {
      const userResponse = await axios.get(`https://sunpay.co.in/api/users/${props.data.id}/`);
      const respUser = userResponse.data;
      setCurrentUser(respUser);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const BASE_URL = 'http://:9000';

  const handleDownload = (url, filename) => {
    fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/octet-stream',
        },
    })
    .then((response) => response.blob())
    .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(blobUrl);
    })
    .catch((error) => console.error('Error downloading the file:', error));
};

  const showalltrans = async () => {
    const userid = parseInt(props.data.id);
    try {
      const transresp = await axios.get(`https://sunpay.co.in/api/get_fund_request/?is_admin=true`);
      
      // Log the entire response to understand its structure
      console.log('transresp', transresp);
  
      // Check if transresp.data exists and has the expected structure
      if (transresp.data && transresp.data.Data) {
        const resptran = transresp.data.Data;
    
        const filteredTrans = resptran.filter((item) => {
          const itemDate = new Date(item.add_date).toISOString().split('T')[0];
          return itemDate >= fromDate && itemDate <= toDate;
        });
  
        setAllFundRequest(filteredTrans);
        setshowrow(true);
      } else {
        alert('Unexpected response structure:', transresp.data);
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };
  
  // const getFundRequest = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const fundReqResponse = await axios.get(`https://sunpay.co.in/api/get_fund_request/?user_id=${props.data.id}`);
  //     const allRequest = fundReqResponse.data;
  //     setAllFundRequest(allRequest.Data);
  //     console.log(allFundRequest)
  //     setShowRow(true);
  //   } catch (error) {
  //     console.error('Error fetching fund requests:', error);
  //   }
  // };

  useEffect(() => {
    fetchPreDetail();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleEditClick = (item) => {
    setSelectedItem(item); // Set the selected item
    setIsModalOpen(true);  // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
    setSelectedItem(null); // Reset the selected item
  };

  const formatDate = (dateTimeString) => {
    const dateObj = new Date(dateTimeString);
    const dd = String(dateObj.getDate()).padStart(2, '0');
    const mm = String(dateObj.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yy = dateObj.getFullYear().toString().substr(-2);
    return `${dd}/${mm}/${yy}`;
  };

  // Function to format time as HH:MM:SS
  const formatTime = (dateTimeString) => {
    const dateObj = new Date(dateTimeString);
    const HH = String(dateObj.getHours()).padStart(2, '0');
    const MM = String(dateObj.getMinutes()).padStart(2, '0');
    const SS = String(dateObj.getSeconds()).padStart(2, '0');
    return `${HH}:${MM}:${SS}`;
  };

  const exportToExcel = () => {
    const table = document.getElementById('myTable');
    const wb = XLSX.utils.table_to_book(table, { sheet: 'Sheet1' });
    XLSX.writeFile(wb, 'table_data.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.autoTable({ html: '#myTable' });
    doc.save('table_data.pdf');
  };

 

  return (
    <div className='p-4'>
             <UpdateModel isOpen={isModalOpen} onClose={closeModal} currentuser={currentuser} item={selectedItem} />

      <div className='p-4 bg-gray-500 pb-6'>
      <div className='mt-4'>
          <div className='datefetch flow-root gap-6'>
            
            <div className='float-left'>
              <div className='datefetch md:flex md:flex-wrap gap-6'>
                  {/* From Date */}
                  <div className="col-md-3">
                    <label htmlFor="fromDate" className='text-white'>From Date</label>
                    <input
                      id="fromDate"
                      type="date"
                      onChange={handleChange}
                      name="fromDate"
                      defaultValue={defaultValue}
                      className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    />
                  </div>

                  {/* To Date */}
                  <div className="col-md-3">
                    <label htmlFor="toDate" className='text-white'>To Date</label>
                    <input
                      id="toDate"
                      type="date"
                      onChange={handleChange}
                      name="toDate"
                      defaultValue={defaultValue}
                      className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    />
                  </div>

                  <div className="col-md-3">
                    <input
                      type="submit"
                      value='Submit'
                      onClick={showalltrans}
                      className='mt-6 bg-blue-600 text-white border border-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    />
                  </div>
              </div>
            </div>

            <div className='float-right'>
                  <label htmlFor="Payment date" className='text-white text-xl'>Search</label>
                    <div className="col-md-3">
                      <input id="dateRequired" type="search" onChange={handleChange} name="dateRequired" className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'/>
                      {/* <input type="date" onChange={handleChange} ref={dateInputRef} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'/> */}
                    </div>
            </div>

          </div>

          {/* <div className='mt-4'>
            <div className =" relative overflow-x-auto shadow-md border-black ">
                 <table className=" text-sm text-left rtl:text-right mt-2 border border-black text-gray-500 dark:text-gray-400 border-collapse ">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr className='border border-black'>
                        <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">SNO</th>
                        <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Action</th>
                        <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Status</th>
                        <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">MemberID</th>
                        <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Shopname & Mobile-No.</th>
                        <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Payment Date</th>
                        <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Payment Mode</th>
                        <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Company Bank Name</th>
                        <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Amount</th>
                        <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Bank Reference ID</th>
                        <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Transaction Id</th>
                        <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Remark</th>
                        <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Add Date</th>
                        <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Update Date</th>
                        <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Bond</th>
                        <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Company Remarks</th>
                        <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Slip</th>
                      </tr>
                    </thead>
                  
                  {showRow && (
                      <tbody>
                          {Array.isArray(allFundRequest) && allFundRequest.length > 0 ? (
                            allFundRequest.map((item, index) => (
                              <tr key={item.id} className='bg-white'>
                                <td className="px-6 py-3 border border-black">{index + 1}</td>
                                <td className="px-6 py-3 border border-black">
                                {item.transaction_status_display === 'PENDING' || item.transaction_status_display === 'REINITIATE' ? (
                                    <button onClick={() => handleEditClick(item)} className="px-4 py-2 bg-blue-500 text-white rounded-lg">Edit</button>
                                ) : (
                                    <div></div>
                                )}
                                </td>
                                <td className="px-6 py-3 border border-black whitespace-nowrap">
                                      <button
                                          className={`px-4 py-2 rounded-lg text-white ${
                                          item.transaction_status_display === 'PENDING' ? 'bg-yellow-300' :
                                          item.transaction_status_display === 'SUCCESS' ? 'bg-green-600' :
                                          item.transaction_status_display === 'FAILURE' ? 'bg-red-500' :
                                          item.transaction_status_display === 'REINITIATE' ? 'bg-gray-800' :
                                            'bg-none' // Default color if none of the above match
                                          }`}
                                        >
                                        {item.transaction_status_display}
                                        </button>                                </td>
                                <td className="px-6 py-3 border border-black whitespace-nowrap">{item.userdet.username}</td>
                                <td className="px-6 py-3 border border-black whitespace-nowrap">{item.userdet.shop_name}<br/>{item.userdet.mobile}</td>
                                <td className="px-4 py-3 border border-black whitespace-nowrap">{item.payment_date}</td>
                                <td className="px-6 py-3 border border-black whitespace-nowrap">{item.payment_mode_display}</td>
                                <td className="px-6 py-3 border border-black whitespace-nowrap">{item.bank_name}</td>
                                <td className="px-6 py-3 border border-black whitespace-nowrap">{item.amount}</td>
                                <td className="px-6 py-3 border border-black whitespace-nowrap">{item.bank_ref_number}</td>
                                <td className="px-6 py-3 border border-black">{item.reference_number}</td> 
                                <td className="px-6 py-3 border border-black whitespace-nowrap">{item.remark}</td>
                                <td className="px-6 py-3 border border-black whitespace-nowrap">On:{formatDate(item.add_date)} <br/>At:{formatTime(item.add_date)}</td>
                                <td className="px-6 py-3 border border-black whitespace-nowrap">On:{formatDate(item.lastupdate)} <br/>At:{formatTime(item.lastupdate)}</td>
                                <td className="px-6 py-3 border border-black whitespace-nowrap">
                                  <button className="px-4 py-2 bg-blue-500 text-white rounded-lg">Bond</button>
                                </td>
                                <td className="px-6 py-3 border border-black whitespace-nowrap">{item.payment_remark}</td>                                
                                <td className="px-6 py-3 border border-black whitespace-nowrap">
                                    {item.receipt ? (
                                      <img 
                                      src={`${BASE_URL}/${item.receipt}`} 
                                      alt="Receipt" 
                                      className="w-20 h-20 object-cover cursor-pointer" 
                                      onClick={() => handleDownload(`${BASE_URL}/${item.receipt}`, `receipt_${item.receipt}.png`)}
                                      />
                                      ) : (
                                        <div></div>
                                    )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="14" className="text-center py-3 border border-black">No Fund Requests Found</td>
                            </tr>
                          )}
                      </tbody>
                      )}              
                 </table>
            </div>
          </div> */}

          <div className='mt-4'>
          <button onClick={exportToExcel} className="px-4 py-2 bg-green-500 text-white rounded-lg">Download as Excel</button>
          <button onClick={exportToPDF} className="px-4 py-2 bg-red-500 text-white rounded-lg ml-4">Download as PDF</button>
          <div className="relative overflow-x-auto shadow-md border-black mt-4">
            <table id="myTable" className="text-sm text-left rtl:text-right mt-2 border border-black text-gray-500 dark:text-gray-400 border-collapse">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr className='border border-black'>
                  <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">SNO</th>
                  <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Action</th>
                  <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Status</th>
                  <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">MemberID</th>
                  <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Shopname & Mobile-No.</th>
                  <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Payment Date</th>
                  <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Payment Mode</th>
                  <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Company Bank Name</th>
                  <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Amount</th>
                  <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Bank Reference ID</th>
                  <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Transaction Id</th>
                  <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Remark</th>
                  <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Add Date</th>
                  <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Update Date</th>
                  <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Bond</th>
                  <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Company Remarks</th>
                  <th scope="col" className="px-6 py-3 border border-black whitespace-nowrap">Slip</th>
                </tr>
              </thead>
              {showRow && (
                <tbody>
                  {Array.isArray(allFundRequest) && allFundRequest.length > 0 ? (
                    allFundRequest.map((item, index) => (
                      <tr key={item.id} className='bg-white'>
                        <td className="px-6 py-3 border border-black">{index + 1}</td>
                        <td className="px-6 py-3 border border-black">
                          {item.transaction_status_display === 'PENDING' || item.transaction_status_display === 'REINITIATE' ? (
                            <button onClick={() => handleEditClick(item)} className="px-4 py-2 bg-blue-500 text-white rounded-lg">Edit</button>
                          ) : (
                            <div></div>
                          )}
                        </td>
                        <td className="px-6 py-3 border border-black whitespace-nowrap">
                          <button className={`px-4 py-2 rounded-lg text-white ${
                            item.transaction_status_display === 'PENDING' ? 'bg-yellow-300' :
                            item.transaction_status_display === 'SUCCESS' ? 'bg-green-600' :
                            item.transaction_status_display === 'FAILURE' ? 'bg-red-500' :
                            item.transaction_status_display === 'REINITIATE' ? 'bg-gray-800' :
                            'bg-none'
                          }`}>
                            {item.transaction_status_display}
                          </button>
                        </td>
                        <td className="px-6 py-3 border border-black whitespace-nowrap">{item.userdet.username}</td>
                        <td className="px-6 py-3 border border-black whitespace-nowrap">{item.userdet.shop_name}<br/>{item.userdet.mobile}</td>
                        <td className="px-4 py-3 border border-black whitespace-nowrap">{item.payment_date}</td>
                        <td className="px-6 py-3 border border-black whitespace-nowrap">{item.payment_mode_display}</td>
                        <td className="px-6 py-3 border border-black whitespace-nowrap">{item.bank_name}</td>
                        <td className="px-6 py-3 border border-black whitespace-nowrap">{item.amount}</td>
                        <td className="px-6 py-3 border border-black whitespace-nowrap">{item.bank_ref_number}</td>
                        <td className="px-6 py-3 border border-black">{item.reference_number}</td>
                        <td className="px-6 py-3 border border-black whitespace-nowrap">{item.remark}</td>
                        <td className="px-6 py-3 border border-black whitespace-nowrap">On:{formatDate(item.add_date)} <br/>At:{formatTime(item.add_date)}</td>
                        <td className="px-6 py-3 border border-black whitespace-nowrap">On:{formatDate(item.lastupdate)} <br/>At:{formatTime(item.lastupdate)}</td>
                        <td className="px-6 py-3 border border-black whitespace-nowrap">
                          <button className="px-4 py-2 bg-blue-500 text-white rounded-lg">Bond</button>
                        </td>
                        <td className="px-6 py-3 border border-black whitespace-nowrap">{item.payment_remark}</td>
                        <td className="px-6 py-3 border border-black whitespace-nowrap">
                          {item.receipt ? (
                            <img
                              src={`${BASE_URL}/${item.receipt}`}
                              alt="Receipt"
                              className="w-20 h-20 object-cover cursor-pointer"
                              onClick={() => handleDownload(`${BASE_URL}/${item.receipt}`, `receipt_${item.receipt}.png`)}
                            />
                          ) : (
                            <div></div>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="14" className="text-center py-3 border border-black">No Fund Requests Found</td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>
          </div>
          <div>
       
    </div>
        </div>
      </div>
    </div>
  )
}

export default FundRequest
